<template>
  <v-row class="negative-margin my-0">
    <v-col
      v-for="(infoItem, index) in infoSections"
      :key="index"
      class="fill-height padding-2"
      cols="6"
      sm="3"
    >
      <div class="white rounded pa-3 fill-height d-flex flex-column justify-space-between align-start">
        <div class="text-caption text--black mb-2">
          {{ infoItem.title }}
        </div>
        <custom-chip-status
          v-if="infoItem.chip"
          small
          label
          :class="infoItem.value.chip.class"
          :text-color="infoItem.value.chip.textColor"
          :with-icon="infoItem.value.chip.withIcon"
          :value="infoItem.value.chip.status"
          :settings="infoItem.value.settings"
        />
        <div v-else class="text-h6 text-sm-body-1 black--text font-weight-bold text-capitalize">
          <v-skeleton-loader :loading="isLoading" type="chip">
            {{ infoItem.value || '' }}
          </v-skeleton-loader>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Ads from '@/services/classes/Ads.js'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'

  export default {
    name: 'AdInfo',
    components: { CustomChipStatus },
    props: {
      ad: {
        type: Ads,
        default: () => ({})
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        statusTypes: Ads.getStatusTypes()
      }
    },
    computed: {
      ...mapGetters('approvables', ['adsApprovalStatusTypes']),
      infoSections() {
        return [
          {
            chip: true,
            title: this.$t('main.status'),
            value: {
              chip: {
                textColor: 'black',
                withIcon: true,
                status: this.ad.status
              },
              settings: this.statusTypes
            }
          },
          {
            chip: true,
            title: this.$t('main.approval_status'),
            value: {
              chip: {
                class: 'font-weight-bold',
                withIcon: false,
                status: this.ad.latestApproval.status
              },
              settings: this.adsApprovalStatusTypes
            }
          },
          {
            title: 'ID',
            value: `#${this.ad.id}`
          },
          {
            title: this.$t('main.ad_format'),
            value: this.ad.adFormatType
          }
        ]
      }
    }
  }
</script>

<style scoped lang="scss">
  .negative-margin {
    margin-inline: -2px;
  }
  .padding-2 {
    padding: 2px;
  }
</style>
