<template>
  <v-card class="py-5 px-4" elevation="0">
    <v-card-title class="pa-0">
      {{ $t('main.ad_form.parameters') }}
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="adIsLoading"
      hide-default-footer
      hide-default-header
      disable-pagination
      :mobile-breakpoint="0"
    >
      <template #item.value="{ item }">
        <src-outer-link v-if="item.link" :src="item.link" />

        <direct-link-icon v-else-if="item.icon" :icon="item.icon" />

        <template v-else-if="item.list">
          <ul class="pl-0">
            <li
              v-for="(scanReport, index) in item.list.scanReport"
              :key="index"
              class="d-flex justify-end"
            >
              <a :href="scanReport" target="_blank">
                {{ item.list.title }}
              </a>
            </li>
          </ul>
        </template>

        <span v-else>
          {{ item.value }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

  import SrcOuterLink from '@/components/ads/SrcOuterLink.vue'
  import DirectLinkIcon from '@/components/DirectLinkIcon.vue'
  import Ads from '@/services/classes/Ads.js'

  export default {
    name: 'NewParametersCard',
    components: { DirectLinkIcon, SrcOuterLink },
    props: {
      ad: {
        type: Ads,
        required: true
      },
      adIsLoading: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      headers() {
        return [
          { value: 'name', align: 'start pl-0 text--secondary text-caption' },
          { value: 'value', align: 'end text-caption font-weight-bold' }
        ]
      },
      items() {
        const items = [
          {
            name: this.$t('ads.content_feed_name'),
            value: this.ad.model.contentFeedName,
            isShown: !!this.ad.model.contentFeedName
          },
          {
            name: this.$t('ads.content_feed_url'),
            link: this.ad.model.contentFeedUrl,
            isShown: !!this.ad.model.contentFeedUrl
          },
          {
            name: this.$t('ads.provider_website'),
            link: this.ad.model.providerWebsite,
            isShown: !!this.ad.model.providerWebsite
          },
          {
            name: this.$t('ads.type'),
            value: this.$t(`main.rotation_types.${this.ad.model.type}`),
            isShown: !!this.ad.model.type
          },
          {
            name: 'URL',
            link: this.ad.model.src,
            isShown: !!this.ad.model.src
          },
          {
            name: this.$t('ads.title'),
            value: this.ad.model.title,
            isShown: !!this.ad.model.title
          },
          {
            name: this.$t('ads.brand'),
            value: this.ad.model.brand,
            isShown: !!this.ad.model.brand
          },
          {
            name: this.$t('ads.icon'),
            icon: this.ad.model.icon,
            isShown: !!this.ad.model.icon
          },
          {
            name: this.$t('ads.created_at'),
            value: this.ad.createdAt
          },
          {
            name: this.$t('ads.skip_time'),
            value: `${this.ad.model.skipTime} ${this.$t('ads.seconds')}`,
            isShown: !!this.ad.model.skipTime
          },
          {
            name: this.$t('ads.reject_cause'),
            value: this.ad.latestApproval.rejectCause.details,
            isShown: !!this.ad.latestApproval.rejectCause.details
          },
          {
            name: this.$t('ads.cause_details'),
            list: { scanReport: this.ad.scanReports, title: this.$t('ads.open') },
            isShown: !!window.localStorage.getItem('isBeta')
          },
          {
            name: this.$t('ads.impressions_threshold'),
            value: this.ad.model.rotation?.impressionsThreshold,
            isShown: !!this.ad.model.rotation?.impressionsThreshold
          },
          {
            name: this.$t('ads.hours_threshold'),
            value: this.ad.model.rotation?.hoursThreshold,
            isShown: !!this.ad.model.rotation?.hoursThreshold
          },
          {
            name: this.$t('ads.rotation_type'),
            value: this.ad.model.rotation?.type,
            isShown: !!this.ad.model.rotation?.type
          },
          {
            name: this.$t('ads.size'),
            value: this.ad.size,
            isShown: !!this.ad.size
          },
          {
            name: this.$t('ads.smart_progress'),
            value: this.ad.model.rotation?.smartProgress && `${this.ad.model.rotation.smartProgress * 100}%`,
            isShown: !!(
              this.ad.model.rotation?.smartProgress || this.ad.model.rotation?.smartProgress === 0
            )
          },
          {
            name: this.$t('ads.common_url'),
            link: this.ad.commonUrl,
            isShown: !!this.ad.commonUrl
          }
        ]
        return items.filter(({ isShown }) => isShown !== false)
      }
    }
  }
</script>
