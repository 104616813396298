<template>
  <div>
    <div v-if="isTableOrMobile">
      <a
        v-if="urlTypeIsVideo"
        class="d-flex justify-center preview-container"
        target="_blank"
        :href="src"
      >
        <v-icon size="50"> mdi-file-video-outline </v-icon>
      </a>
      <v-img
        v-else
        :src="src"
        width="100%"
        max-width="100"
        max-height="100"
        @click="isShown = true"
      />
      <hover-image-dialog :src="src" :dialog-is-shown="isShown" @close="isShown = false" />
    </div>
    <div v-else>
      <v-tooltip right>
        <template #activator="{ on }">
          <v-img
            v-if="!urlTypeIsVideo"
            :src="src"
            width="100%"
            max-width="100"
            max-height="100"
            v-on="on"
          />
        </template>
        <v-img :src="src" width="100%" max-width="400" max-height="400" class="pa-2" />
      </v-tooltip>

      <a
        v-if="urlTypeIsVideo"
        class="d-flex justify-center preview-container hover-preview"
        target="_blank"
        :href="src"
      >
        <v-icon size="50"> mdi-file-video-outline </v-icon>
      </a>
    </div>
  </div>
</template>

<script>
  import HoverImageDialog from '@/components/HoverImage/HoverImageDialog.vue'

  export default {
    name: 'HoverImage',
    components: {
      HoverImageDialog
    },
    inject: ['settings'],
    props: {
      src: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isShown: false
      }
    },
    computed: {
      urlTypeIsVideo() {
        const type = this.src.slice(-3)
        return type === 'mp4'
      },
      isTableOrMobile() {
        return this.$vuetify.breakpoint.smAndDown
      }
    }
  }
</script>

<style lang="scss" scoped>
  .preview-container {
    border-radius: 5px;
    text-decoration: none;
    border: 2px solid var(--v-secondary-darken-base);
    cursor: pointer;
    height: 100px;
    width: 100px;
  }
  .hover-preview:hover {
    border: 2px solid var(--v-primary-base);
    & .v-icon {
      color: var(--v-primary-base);
    }
  }
</style>
