<template>
  <v-dialog v-model="isShown" content-class="ma-2">
    <v-card class="fill-height">
      <v-card-text class="d-flex flex-column align-end">
        <c-btn
          icon
          class="mt-2"
          :icon-props="{
            icon: '$close',
            size: '20'
          }"
          @click="closeDialog()"
        />
        <v-img :src="src" width="100%" max-height="400" contain @click="closeDialog()" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'HoverImageDialog',
    components: {
      CBtn
    },
    props: {
      dialogIsShown: {
        type: Boolean,
        required: true
      },
      src: {
        type: String,
        required: true
      }
    },
    computed: {
      isShown: {
        get() {
          return this.dialogIsShown
        },
        set() {
          this.$emit('close')
        }
      }
    },
    methods: {
      closeDialog() {
        this.isShown = false
        this.$emit('close')
      }
    }
  }
</script>
