<template>
  <v-card elevation="0" class="border-secondary">
    <v-card-title class="secondary">
      {{ $t('ads.creatives') }}
    </v-card-title>
    <v-skeleton-loader :loading="adIsLoading" type="list-item-avatar, list-item-avatar">
      <v-data-table
        :headers="headers"
        :items="ad.model.creatives"
        hide-default-footer
        :options.sync="options"
        @page-count="getPageCount($event)"
        @update:sort-by="changeCurrentPage()"
      >
        <template #item.url="{ item }">
          <adaptive-string-table>
            <a :href="item.url" target="_blank">
              {{ item.url }}
            </a>
          </adaptive-string-table>
        </template>
      </v-data-table>
      <data-table-footer
        :items-per-page="options.itemsPerPage"
        :page-count="pageCount"
        :current-page="options.page"
        @change-items-per-page="changeItemsPerPage($event)"
        @change-current-page="changeCurrentPage($event)"
      />
    </v-skeleton-loader>
  </v-card>
</template>

<script>
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import Ads from '@/services/classes/Ads.js'

  export default {
    name: 'IosCalendarCreativesCard',
    components: {
      DataTableFooter
    },
    props: {
      ad: {
        type: Ads,
        required: true
      },
      adIsLoading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        options: {
          itemsPerPage: 15,
          page: 1
        },
        pageCount: 0
      }
    },
    computed: {
      headers() {
        return [
          {
            value: 'id',
            text: 'ID',
            width: '80px'
          },
          {
            value: 'title',
            text: this.$t('ads.title'),
            width: '100px'
          },
          {
            value: 'description',
            text: this.$t('ads.creative'),
            width: '200px'
          },
          {
            value: 'url',
            text: 'URL',
            width: '200px'
          }
        ]
      }
    },
    methods: {
      changeItemsPerPage(itemsPerPage) {
        this.options.page = 1
        this.options.itemsPerPage = itemsPerPage
      },
      getPageCount(pageCount) {
        this.options.page = 1
        this.pageCount = pageCount
      },
      changeCurrentPage(page = 1) {
        this.options.page = page
      }
    }
  }
</script>
