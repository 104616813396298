<template>
  <v-card class="pb-5 px-4" elevation="0">
    <v-card-title class="px-0">
      {{ $t('main.campaigns') }}
    </v-card-title>
    <v-responsive class="overflow-y-auto" max-height="340">
      <v-data-table
        :headers="headers"
        :items="campaigns"
        :loading="isLoading"
        hide-default-footer
        :items-per-page="-1"
        mobile-breakpoint="0"
      >
        <template #item.name="{ item }">
          <router-link class="ml-n3 text-caption" :to="{ name: 'Campaign', params: { id: item.id } }">
            {{ item.name }}
          </router-link>
        </template>
        <template #item.status="{ item }">
          <campaign-status :campaign="item" />
        </template>
      </v-data-table>
    </v-responsive>
  </v-card>
</template>

<script>
  import CampaignStatus from '@/components/CampaignStatus.vue'

  export default {
    name: 'NewAdCampaigns',
    components: {
      CampaignStatus
    },
    props: {
      campaigns: {
        type: Array,
        default: () => []
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      headers() {
        return [
          {
            value: 'name',
            text: this.$t('main.name'),
            class: 'black--text pl-0'
          },
          {
            value: 'status',
            text: this.$t('main.status'),
            class: 'black--text'
          }
        ]
      }
    }
  }
</script>
