<template>
  <v-dialog scrollable :value="isShown" :max-width="adSizes.width" @input="closeDialog()">
    <v-card>
      <v-card-text class="d-flex align-center pa-0">
        <iframe
          :src="previewUrl"
          frameBorder="0"
          :width="adSizes.width"
          :height="adSizes.height"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    name: 'Html5PreviewDialog',
    props: {
      isShown: {
        type: Boolean,
        required: true
      },
      previewUrl: {
        type: String,
        required: true
      },
      adSizes: {
        type: Object,
        required: true
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close')
      }
    }
  }
</script>
